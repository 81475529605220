export const menuMasterAdminItems = [
    {
        id: 1,
        label: "Admin Panel",
        isTitle: true
    },
    {
        id: 2,
        label: "Dashboard",
        icon: "uil-home-alt",
        link: "/"
    },
    {
        id: 3,
        label: "Summary",
        icon: "uil-chart",
        link: "/summary"
    },
    {
        id: 4,
        label: "UPI Deposit",
        icon: "uil-credit-card",
        link: "/upi-deposit"
    },
    {
        id: 5,
        label: "IMPS Deposit",
        icon: "uil-bill",
        link: "/imps-deposit"
    },
    {
        id: 6,
        label: "Sales Reports",
        icon: "uil-chart-pie",
        link: "/sales-reports"
    },
    {
        id: 12,
        label: "Financial Reports",
        icon: "uil-dollar-alt",
        link: "/financial-reports"
    },
    {
        id: 7,
        label: "Merchant Reports",
        icon: "uil-chart-line",
        link: "/merchant-reports"
    },
    {
        id: 8,
        label: "Withdrawal",
        icon: "uil-money-withdrawal",
        link: "/withdrawal"
    },
    {
        id: 9,
        label: "Merchant",
        icon: "uil-users-alt",
        link: "/merchant"
    },
    {
        id: 10,
        label: "Bank Account",
        icon: "uil-usd-circle",
        link: "/bank-accounts"
    },
    {
        id: 11,
        label: "Settings",
        icon: "uil-setting",
        link: "/settings"
    }
];