export const menuMerchantItems = [
    {
        id: 1,
        label: "Merchant",
        isTitle: true
    },
    {
        id: 2,
        label: "Dashboard",
        icon: "uil-home-alt",
        link: "/"
    },
    {
        id: 3,
        label: "Summary",
        icon: "uil-chart",
        link: "/summary"
    },
    {
        id: 4,
        label: "UPI Deposit",
        icon: "uil-credit-card",
        link: "/upi-deposit"
    },
    {
        id: 5,
        label: "IMPS Deposit",
        icon: "uil-bill",
        link: "/imps-deposit"
    },
    {
        id: 6,
        label: "Settings",
        icon: "uil-setting",
        link: "/settings"
    }
];
